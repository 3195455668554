@font-face {
  font-family: 'Kumbh Sans';
  src: url('./assets/font.ttf') format('truetype');
  font-style: normal;
}

body{
  background-color: black;
}
.container {
  background-image: url("assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px;
  min-height: 100vh;
  background-color: #F9F9F9;
}

.container2 {
  display: grid;
  grid-template-rows: 1fr auto; 
  background-image: url("assets/bg2.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 50px;
  padding-bottom: 10px;
  min-height: 90vh;
  background-color: #F9F9F9;
}

.container-black {
  background-color: black;
  min-height: 50vh;
  padding: 0 100px; /* Give some padding to the container */
  padding-bottom: 100px;
}

.green-subtitle-left {
  color: #BDFF00;
  text-align: left; /* Align the text to the left */
  max-width: 600px;
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 45px;
  margin-bottom: 50px;
}

.green-subtitle-right {
  color: #BDFF00;
  text-align: right; /* Align the text to the right */
  font-family: 'Kumbh Sans', sans-serif;
  font-size: 45px;
  padding-left: 500px;
}

h3{
  color: #000000;
  font-size: 30px;
  text-align: left;
  font-weight: 600;
  font-family: 'Kumbh Sans', sans-serif;
  margin-left: 50px;
  line-height: 50px;
  margin-top: 50px;
}



.header {
  float: left;
  margin-top: -20px;
  margin-left: -20px;
}

.main-title{
  color: black;
  margin-top: 100px;
  font-size: 75px;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 650;
  margin-bottom: 0px;
}

.sub-title {
  color: black;
  font-size: 25px;
  font-family: 'Kumbh Sans', sans-serif;
}

.sub-title2 {
  color: #000000;
  font-size: 50px;
  text-align: left;
  font-weight: 600;
  font-family: 'Kumbh Sans', sans-serif;
  margin-left: 50px;
}

.sub-title3 {
  color: #000000;
  font-size: 30px;
  text-align: left;
  margin-right: 50px;
  font-weight: 400;
  font-family: 'Kumbh Sans', sans-serif;
  margin-left: 50px;
}


.cta-button {
  margin-top: 70px;
  background-color: #BDFF00;
  border-radius: 5px;
  padding: 12px 70px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  border-style: solid;
  border-width: 1.5px;
  color: #000000;
  border-color: #000000;
}

.cta-button:hover {
  background-color: #000000;
  color: #BDFF00;
  border-color: #000000;
}

.main-section{
  display: flex;
  justify-content: space-between;
}

.form {
  margin-top: 100px;
  background-color: #BDFF00;
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 16px;
  border: none;
  margin-right: 10px;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 600;
  border-style: solid;
  border-width: 1.5px;
}

input:focus {
  outline: 2px solid white;
}

.image1{
  width: 505px;
  height: fit-content;
  margin-right: 50px;
  margin-top: 50px;
}


.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #BDFF00;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}


.animated-element {
  transform: translateY(70px);
  opacity: 0;
  transition: transform 0.7s ease-out, opacity 0.7s ease-out;
}

.animated-element.in-view {
  transform: translateY(0);
  opacity: 1;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  background-color: transparent;
  font-family: 'Kumbh Sans', sans-serif;
  font-weight: 500;
  font-size: 17px;
  color: #F9F9F9;
  
}

.logo {
  width: 200px;
}
.logo-footer {
  width: 50px;
}
.footer-center {
  text-align: center;
  margin-left: 60px;
}

.footer-right {
  display: flex;
  align-items: center;
}

.x-icon {
  height: 50px;
  margin-left: 10px;
}

.linkedin-icon {
  height: 70px;
  margin-left: 10px;
}




/* Mobile Styles */
@media only screen and (max-width: 768px) {
  .container {
    padding: 20px;
  }

  .header {
    float: none;
    margin: 0;
  }

  .header .logo {
    float: none;
    margin: 0px auto 0;
    display: block;
  }

  .main-title {
    font-size: 24px;
    margin-left: 0px;
  }

  .sub-title {
    font-size: 18px;
    margin-left: 0px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: 500;
  }

  .sub-title2 {
    font-size: 24px;
    margin-left: 0px;
    margin-top: -20px;
    font-weight: 500;
  }

  .sub-title3{
    font-size: 18px;
    margin-left: 0px;
    margin-right: 0px;
  }

  h3{
    font-size: 18px;
    line-height: 30px;
    margin-left: 0px;
    margin-top: 80px;
    font-weight: 500;
  }

  .main-section{
    display: flex;
    flex-direction: column;
  }

  .green-subtitle-left {
    font-size: 24px;
    margin-left: 0px;
    
  }

  .container-black{
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .green-subtitle-right {
    font-size: 24px;
    margin-right: 0px;
    padding-left: 0px;
  }

  .cta-button {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 16px;
    margin-top: -10px;
  }

  .container2{
    min-height: 10vh;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;
    background-color: transparent;
    font-family: 'Kumbh Sans', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: #F9F9F9;
  }

  .footer-center{
    margin-left: 40px;
  }

  .logo-footer {
    width: 20px;
  }

  .x-icon {
    height: 20px;
  }

  .linkedin-icon {
    height: 25px;
  }
  

}
