

@font-face {
    font-family: 'Font';
    src: url('./assets/font.ttf') format('truetype');
    font-style: normal;
  }

.waitlist-container {
    display: flex;
    width: 100%;
    height: auto;
    font-family: 'Font', sans-serif;
    font-weight: 500;
    font-size: 20px;
  }

  h1{
    font-weight: 700;
    font-size: 90px;
  }

  h2{
    font-weight: 400;
  }
  
  .left-section {
    width: 40%;
    background-color: #F9F9F9;
    border-right: 1.5px solid black; 
    padding-bottom: 50px;
  }
  
  .right-section {
    width: 60%;
    background-color: #BDFF00;
    font-family: 'Font', sans-serif;
    padding-top: 30px;
    padding-left: 100px;
    padding-right: 100px;
    padding-bottom: 50px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-left: 60px;
    margin-top: 100px;
    
  }
  
  form input[type="text"], form input[type="email"] {
    margin-bottom: 1em;
    font-family: 'Font', sans-serif;
    margin-bottom: 30px;
    background-color: transparent;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    border-width: 1.5px;
    height: 30px;
    margin-top: 10px;
  }
  
  form button {
    margin-top: 30px;
    background-color: #BDFF00;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    width: 60%;
    border: none;
    cursor: pointer;
    font-family: 'Font', sans-serif;
    font-weight: 600;
    border-style: solid;
    border-width: 1.5px;
    color: #000000;
    border-color: #000000;
  }

  form button:hover {
    background-color: #000000;
    color: #BDFF00;
    border-color: #000000;
  }
  
  .animated-element {
    transform: translateY(70px);
    opacity: 0;
    transition: transform 0.7s ease-out, opacity 0.7s ease-out;
  }
  
  .animated-element.in-view {
    transform: translateY(0);
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .waitlist-container {
      flex-direction: column-reverse;
      height: auto; /* ensure container height doesn't limit section height */
    }
  
    h1{
      font-size: 30px;
      margin-left: 20px;
      font-weight: 700;
    }
  
    h2{
      font-size: 20px;
      margin-top: 20px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 50px;
    }
    
    .left-section,
    .right-section {
      width: 100%;
      height: 50vh; /* each section takes half of the viewport height */
      padding-left: 0px;
      padding-right: 0px;
      flex: 1; /* each section shares remaining space equally */
    }
  
    .right-section {
      padding-top: 50px;
    }
  
    form button {
      margin-bottom: 40px;
      font-weight: 500;
    }
  }
  
  